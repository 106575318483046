.navigationContainer {
  position: fixed;
  width: 100%;
  max-width: 2560px;
  height: 100px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 3;
}

.navigation {
  width: 50%;
  background-color: rgba(0, 0, 30, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 5px;
}

.burgerNavigation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 8;
}

.burgerNavigation .logo {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  height: 40px;
}

.burgerNavigation p {
  font-size: 20px;
  font-family: var(--font-two);
  text-transform: uppercase;
}

.burgerNavigation p:nth-child(3) {
  font-size: 10px;
}

.burgerNavigationClosed {
  color: var(--color-one);
  background-color: rgba(0, 0, 30, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: inset 0 0 15px var(--color-two);
}

.navList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 36px;
  font-weight: 300;
  font-family: var(--font-one);
  color: var(--color-one);
  list-style: none;
}

.listItem {
  position: relative;
  width: 15%;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}

.listItem a {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.listItem:hover {
  text-shadow: 1px 1px 2px var(--color-two);
}

.listItem::after {
  content: '';
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: 2px;
  background: transparent;
  transition: 0.3s;
  border-radius: 5px;
  z-index: 4;
}

.listItem:hover.listItem::after {
  background: var(--color-one);
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listItem:hover.listItem.logoContainer::after {
  background: transparent;
}

.listItem:hover.listItem.logoContainer {
  cursor: default;
}

.logo {
  height: 35px;
}

.burger {
  display: none;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 35px;
  height: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  cursor: pointer;
  z-index: 8;
}

.burgerBar {
  height: 2px;
  border-radius: 10px;
  background: var(--color-one);
  transition: 0.3s;
}

.burgerBarOne {
  width: 100%;
}

.burgerBarTwo {
  width: 75%;
}

.burgerBarThree {
  width: 50%;
}

.burger:hover .burgerBarTwo,
.burger:hover .burgerBarThree {
  width: 100%;
}

.burger.menuOpen .burgerBarOne {
  transform: translateY(14px) rotate(45deg);
}

.burger.menuOpen .burgerBarTwo {
  display: none;
}

.burger.menuOpen .burgerBarThree {
  width: 100%;
  transform: translateY(-14px) rotate(-45deg);
}

.burger.menuOpen:hover .burgerBarOne {
  transform: translateY(14px) rotate(225deg);
}

.burger.menuOpen:hover .burgerBarThree {
  transform: translateY(-14px) rotate(135deg);
}

@media (max-width: 1200px) {
  .navigationContainer {
    justify-content: center;
  }

  .navigation {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 1080px) {
  .navList {
    font-size: 30px;
  }

  .logo {
    height: 32px;
  }
}

@media (max-width: 790px) {
  @keyframes showNavigation {
    from {
      opacity: 0;
      visibility: hidden;
      height: 0vh;
    }

    to {
      opacity: 1;
      visibility: visible;
      height: 100vh;
    }
  }

  @keyframes closeNavigation {
    from {
      opacity: 1;
      visibility: visible;
      height: 100vh;
    }

    to {
      opacity: 0;
      visibility: hidden;
      height: 0vh;
    }
  }

  .navigationContainer {
    top: 0;
    left: 0;
    height: auto;
  }

  .navigation {
    animation: showNavigation 0.3s both ease-in-out;
  }

  .navigation.navigationClosed {
    animation: closeNavigation 0.3s both ease-in-out;
  }

  .burgerNavigation {
    display: flex;
  }

  .navList {
    background: var(--gradient-color-one);
    flex-direction: column;
    justify-content: space-evenly;
    height: 100vh;
  }

  .listItem {
    width: 90%;
    font-size: 36px;
  }

  .listItem::after {
    display: none;
    background: var(--color-three);
  }

  .listItem.logoContainer::after {
    display: none;
  }

  .navList {
    font-size: 24px;
  }

  .logo {
    height: 50px;
  }

  .burger {
    display: flex;
  }

  .burger.burger.menuOpen {
    display: flex;
  }
}

@media (max-width: 660px) {
  .navList {
    font-size: 20px;
  }

  .logo {
    height: 42px;
  }
}

@media (max-width: 570px) {
  .burger {
    height: 20px;
    width: 25px;
  }

  .burgerNavigation {
    height: 60px;
  }

  .burgerNavigation p {
    font-size: 20px;
  }

  .burgerNavigation p:nth-child(3) {
    font-size: 10px;
  }

  .burgerNavigation .logo {
    height: 30px;
  }

  .burger.menuOpen .burgerBarOne {
    transform: translateY(9px) rotate(45deg);
  }

  .burger.menuOpen .burgerBarThree {
    transform: translateY(-9px) rotate(-45deg);
  }

  .burger.menuOpen:hover .burgerBarOne {
    transform: translateY(9px) rotate(225deg);
  }

  .burger.menuOpen:hover .burgerBarThree {
    transform: translateY(-9px) rotate(135deg);
  }
}
