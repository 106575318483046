.contact {
  padding-top: 70px;
  width: 1200px;
  display: flex;
  justify-content: center;
  background: var(--gradient-color-one);
}

.form {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  position: relative;
  padding-bottom: 50px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper .error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--color-three);
  background-color: var(--color-three);
  text-align: center;
  font-family: var(--font-two);
  color: var(--color-one);
  font-size: 30px;
}

.wrapper label {
  margin-left: 10px;
  margin-bottom: 10px;
  align-self: start;
  font-weight: 500;
  height: 20px;
  color: var(--color-three);
}

.wrapper label,
.wrapper input,
.wrapper textarea,
.wrapper button,
.wrapper .success {
  font-family: var(--font-two);
}

.wrapper input,
.wrapper textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  border-radius: 7px;
  border: 2px solid var(--color-three);
  background-color: transparent;
  outline: none;
}

.wrapper textarea {
  margin-bottom: 30px;
}

.wrapper .invalid {
  box-shadow: 0 0 3px 3px var(--color-three);
}

.wrapper .invalid::placeholder {
  color: var(--color-three);
}

.wrapper input:focus,
.wrapper textarea:focus {
  border: 2px solid var(--color-one);
  box-shadow: 0 0 3px 3px var(--color-one);
}

.wrapper input {
  height: 50px;
}

.wrapper textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
}

.wrapper button {
  width: 30%;
  margin: 10px;
  padding: 10px 12px 8px;
  background: transparent;
  text-transform: uppercase;
  border: 2px solid var(--color-three);
  border-radius: 30px;
  color: var(--color-three);
  font-size: 26px;
  font-weight: 500;
  font-family: var(--font-one);
  cursor: pointer;
  transition: 0.3s;
}

.wrapper .success {
  margin: 10px;
  padding: 5px;
  border: 3px solid transparent;
  font-size: 28px;
  background: var(--gradient-color-two);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wrapper button:focus,
.wrapper button:hover {
  background: var(--color-one);
  border: 2px solid var(--color-one);
  box-shadow: 0 0 3px 3px var(--color-one);
  color: var(--color-two);
}

.wrapper input:not(:placeholder-shown),
.wrapper textarea:not(:placeholder-shown) {
  background: var(--color-one);
}

@media (max-width: 1200px) {
  .contact {
    width: 100%;
  }

  .form {
    width: 80%;
  }
}

@media (max-width: 960px) {
  .wrapper input {
    margin-bottom: 10px;
  }
}

@media (max-width: 790px) {
  .wrapper {
    width: 90%;
  }

  .wrapper input,
  .wrapper textarea {
    font-size: 18px;
  }

  .wrapper button,
  .wrapper .success {
    padding: 3px;
    font-size: 24px;
  }
}

@media (max-width: 590px) {
  .wrapper {
    padding-bottom: 0;
  }
}

@media (max-width: 510px) {
  .wrapper button,
  .wrapper .success {
    font-size: 22px;
  }
}

@media (max-width: 450px) {
  .wrapper .error {
    font-size: 22px;
  }
}

@media (max-width: 380px) {
  .wrapper input {
    height: 40px;
  }

  .wrapper textarea {
    min-height: 180px;
  }

  .wrapper input,
  .wrapper textarea {
    font-size: 16px;
  }

  .wrapper button,
  .wrapper .success {
    font-size: 20px;
  }
}
