.footer {
  width: 100%;
  background: var(--color-two);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid var(--color-three);
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradient-color-one);
}

.copyright {
  margin-right: 6px;
  font-size: 20px;
}

.description {
  margin-right: 20px;
  font-size: 22px;
  font-family: var(--font-two);
  color: var(--color-one);
}

.description span {
  color: var(--color-three);
}

.logo {
  height: 40px;
}

@media (max-width: 1080px) {
  .container {
    width: 90%;
  }

  .description {
    font-size: 20px;
  }

  .copyright {
    font-size: 20px;
  }

  .logo {
    height: 35px;
  }
}

@media (max-width: 980px) {
  .description {
    font-size: 18px;
  }

  .copyright {
    font-size: 18px;
  }

  .logo {
    height: 30px;
  }
}

@media (max-width: 880px) {
  .footer {
    border-top: none;
  }

  .container {
    flex-direction: column-reverse;
  }

  .copyright {
    font-size: 18px;
  }

  .description {
    margin: 0;
    font-size: 18px;
  }

  .logo {
    margin-bottom: 10px;
    height: 35px;
  }
}

@media (max-width: 760px) {
  .copyright {
    font-size: 16px;
  }

  .description {
    font-size: 16px;
  }

  .logo {
    height: 30px;
  }
}

@media (max-width: 520px) {
  .copyright {
    font-size: 10px;
  }

  .description {
    font-size: 10px;
  }

  .logo {
    height: 24px;
  }
}
