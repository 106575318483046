.about {
  padding-top: 70px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--gradient-color-one);
  overflow: hidden;
}

.aboutInfo {
  width: 65%;
  padding: 20px;
}

.aboutInfoText {
  font-size: 24px;
  font-weight: 300;
  font-family: var(--font-two);
  color: var(--color-one);
  text-align: center;
}

.aboutSubtitle {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 44px;
  font-weight: 300;
  font-family: var(--font-two);
  color: var(--color-three);
  text-align: center;
  text-transform: uppercase;
}

.skillsWrapper {
  margin-bottom: 50px;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 1s ease-out, opacity 0.8s ease-out;
}

.skillsWrapper.animate {
  opacity: 1;
  transform: translateX(0);
}

.skill {
  margin-bottom: 20px;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.skillIcon {
  width: 50px;
  height: 50px;
  color: var(--color-three);
}

.skillText {
  font-size: 18px;
  font-family: var(--font-two);
  color: var(--color-one);
}

.goalsWrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 1s ease-out, opacity 0.8s ease-out;
}

.goalsWrapper.animate {
  opacity: 1;
  transform: translateX(0);
}

.goalsList {
  list-style: none;
}

.goalsListItem {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  font-family: var(--font-two);
  color: var(--color-one);
}

@media (max-width: 1200px) {
  .about {
    width: 100%;
  }
}

@media (max-width: 1080px) {
  .aboutInfoText {
    font-size: 22px;
  }

  .goalsListItem {
    font-size: 22px;
  }
}

@media (max-width: 790px) {
  .aboutSubtitle {
    font-size: 40px;
  }

  .aboutInfo {
    padding: 20px;
    width: 80%;
  }

  .aboutInfoText {
    font-size: 20px;
  }

  .skillText {
    font-size: 16px;
  }

  .goalsListItem {
    font-size: 20px;
  }
}

@media (max-width: 570px) {
  .skillsWrapper {
    margin-bottom: 10px;
  }

  .aboutSubtitle {
    font-size: 32px;
  }

  .aboutInfo {
    padding-left: 0;
    padding-right: 0;

  }

  .aboutInfoText {
    font-size: 18px;
  }

  .skillText {
    font-size: 14px;
  }

  .goalsListItem {
    font-size: 18px;
  }
}
