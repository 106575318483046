:root {
  --color-one: #ffffff;
  --color-two: #00001e;
  --color-three: #0087ff;

  --gradient-color-one: linear-gradient(90deg, rgba(0, 0, 30, 1) 0%, rgba(5, 5, 60, 1) 50%, rgba(0, 0, 30, 1) 100%);
  --gradient-color-two: linear-gradient(
    90deg,
    rgba(0, 220, 240, 1) 0%,
    rgba(0, 176, 248, 1) 50%,
    rgba(0, 135, 255, 1) 100%
  );

  --font-one: 'Teko', sans-serif;
  --font-two: 'Montserrat', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: var(--color-two);
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: 120px;
  font-weight: 300;
  font-family: var(--font-one);
  text-align: center;
  background: var(--gradient-color-two);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

@media (max-width: 1080px) {
  .section-title {
    font-size: 100px;
  }
}

@media (max-width: 790px) {
  .section-title {
    font-size: 80px;
  }
}

@media (max-width: 570px) {
  .section-title {
    font-size: 60px;
  }
}
