.project {
  padding: 26px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 450px;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--font-two);
  border: 2px solid var(--color-two);
  box-shadow: 0 0 15px var(--color-three);
  background: var(--gradient-color-one);
  transition: 0.2s;
}

.project:hover {
  transform: scale(1.03);
}

.projectTitle {
  font-size: 46px;
  font-weight: 300;
  font-family: var(--font-one);
  color: var(--color-three);
  text-align: center;
  text-transform: uppercase;
}

.projectImage {
  width: 100%;
}

.projectDescription {
  min-height: 200px;
  font-size: 22px;
  font-weight: 300;
  color: var(--color-one);
}

.projectLinks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.projectLink,
.projectGithubLink {
  color: var(--color-three);
  transition: 0.3s;
}

.projectLink {
  padding: 20px 12px 18px;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid var(--color-three);
  border-radius: 30px;
  font-family: var(--font-one);
  text-transform: uppercase;
  text-decoration: none;
}

.projectGithubLink {
  font-size: 42px;
}

.projectLink:hover {
  color: var(--color-two);
  border: 2px solid var(--color-one);
  background-color: var(--color-one);
}

.projectGithubLink:hover {
  color: var(--color-one);
}

@media (max-width: 1200px) {
  .project {
    width: 400px;
  }
}

@media (max-width: 1080px) {
  .project {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 350px;
    height: 600px;
  }

  .projectTitle {
    font-size: 44px;
  }

  .projectDescription {
    font-size: 20px;
  }

  .projectLink {
    padding: 18px 12px 16px;
    font-size: 20px;
  }

  .projectGithubLink {
    font-size: 40px;
  }
}

@media (max-width: 960px) {
  .project {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 300px;
    height: 550px;
  }

  .projectTitle {
    font-size: 42px;
  }

  .projectDescription {
    font-size: 18px;
  }
}

@media (max-width: 830px) {
  .project {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 70%;
    height: 600px;
  }

  .projectTitle {
    font-size: 44px;
  }

  .projectDescription {
    min-height: 150px;
    font-size: 20px;
  }

  .projectLink {
    padding: 20px 12px 18px;
    font-size: 22px;
  }

  .projectGithubLink {
    font-size: 42px;
  }
}

@media (max-width: 790px) {
  .project:hover {
    transform: scale(1);
  }
}

@media (max-width: 630px) {
  .project {
    height: 500px;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .projectTitle {
    font-size: 40px;
  }

  .projectDescription {
    min-height: 90px;
    font-size: 16px;
  }

  .projectLinks {
    justify-content: space-between;
  }

  .projectLink {
    padding: 18px 12px 16px;
    height: 38px;
    width: 90px;
  }
  
  .projectGithubLink {
    font-size: 38px;
  }
}
