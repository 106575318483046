.projects {
  padding-top: 70px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--gradient-color-one);
  overflow: hidden;
}

.projectsWrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 1.2s ease-in-out, transform 1.2s ease-in-out;
}

.projectsWrapper.animate {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 1200px) {
  .projects {
    width: 100%;
  }

  .projectsWrapper {
    width: 80%;
  }
}
